<template>
  <div class="title-wrap">
    <h1 :style="{'text-align': align}">
      {{title}}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    align: {
      type: String,
      default: 'left'
    }
  }
}
</script>

<style lang="scss" scoped>
.title-wrap{
  height: 130px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    text-align: left;
    flex: 1;
    padding: 0 20px;
  }
  &::before{
    content: '';
    height: 2px;
    width: 220px;
    background: linear-gradient(270deg, #5eaae7 -1%, #3360d0 99%);
  }
  &::after{
    content: '';
    height: 2px;
    width: 300px;
    background: linear-gradient(270deg, #5eaae7 -1%, #3360d0 99%);
  }
}
</style>